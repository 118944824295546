





































import {
  api
} from "@/api";
import {
  WechatModule
} from "@/store/modules/wechat";
import {
  FriendDetail
} from "@/utils/interface";
import {
  Component,
  Vue
} from "vue-property-decorator";

@Component({
  name: 'friend-desc',
})
export default class FriendDesc extends Vue {
  private username = this.$route.query.username
  private friendInfo: FriendDetail | null = null

  private get wxId(): number {
    return WechatModule.wxId
  }

  private mounted() {
    this.getFriendInfo()
  }

  /**
   * @func 获取好友信息
   */
  private async getFriendInfo() {
    try {
      const res: any = await api.getFriendDetail({
        robot_id: this.wxId,
        username: this.username,
        wechat_show_state: 1
      })
      this.friendInfo = res.detail
      if (this.friendInfo) {
        WechatModule.changeFriendId(this.friendInfo.friend_detail.id)
        WechatModule.changeFriendStrId(this.friendInfo.friend_detail.wxid)
        WechatModule.changeFriendInfo(JSON.stringify(this.friendInfo.friend_detail))
        WechatModule.changeIsChatRoom(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @func 跳转到聊天页面
   */
  private toChat() {
    this.$router.push({
      name: 'Chat'
    })
  }

  /**
   * @func 设置备注
   */
  private toSetRemark() {
    this.$router.push({
      name: 'Remark',
      query: {
        wechat: this.friendInfo ? this.friendInfo.friend_detail.wechat : ''
      }
    })
  }

  /**
   * @func 跳转到好友朋友圈
   */
  private toMoment() {
    this.$router.push({
      name: 'FriendMoment',
      query: {
        nickname: this.friendInfo ? (this.friendInfo.friend_detail.remark || this.friendInfo.friend_detail
          .nick_name) : '',
        wxStrId: this.friendInfo ? this.friendInfo.friend_detail.wechat : ''
      }
    })
  }
}
